//Gallery
import gallery1 from "../images/Gallery_01.jpg";
import gallery2 from "../images/Gallery_02.webp";
import gallery3 from "../images/Gallery_03.webp";
import gallery4 from "../images/Gallery_04.webp";
import gallery5 from "../images/Gallery_05.webp";
import gallery6 from "../images/Gallery_06.jpg";
import gallery7 from "../images/Gallery_07.webp";
import gallery8 from "../images/Gallery_08.webp";
import gallery9 from "../images/Gallery_09.webp";
import gallery10 from "../images/Gallery_10.webp";
import gallery11 from "../images/Gallery_11.webp";
import gallery12 from "../images/Gallery_12.webp";

const photos = [
  {
    src: gallery1,
    width: 251,
    height: 376,
  },
  {
    src: gallery3,
    width: 98,
    height: 87,
  },
  {
    src: gallery5,
    width: 391,
    height: 531,
  },
  {
    src: gallery7,
    width: 196,
    height: 135,
  },
  {
    src: gallery9,
    width: 391,
    height: 516,
  },
  {
    src: gallery11,
    width: 391,
    height: 348,
  },
  {
    src: gallery2,
    width: 195,
    height: 139,
  },

  {
    src: gallery4,
    width: 392,
    height: 447,
  },
  {
    src: gallery6,
    width: 376,
    height: 251,
  },
  {
    src: gallery8,
    width: 98,
    height: 131,
  },
  {
    src: gallery10,
    width: 391,
    height: 278,
  },
  {
    src: gallery12,
    width: 391,
    height: 447,
  },
]


export default photos // you must export it to use in another file.