import event1 from "../../assets/images/LavishaWay/Events/event-1.jpg";
import event2 from "../../assets/images/LavishaWay/Events/event-2.jpg";
import event3 from "../../assets/images/LavishaWay/Events/event-3.jpg";
import event4 from "../../assets/images/LavishaWay/Events/event-4.jpg";
import event5 from "../../assets/images/LavishaWay/Events/event-5.jpg";
import event6 from "../../assets/images/LavishaWay/Events/event-6.jpg";

import newactivities1 from "../../assets/images/LavishaWay/Activities/newactivities1.jpg";
import newactivities2 from "../../assets/images/LavishaWay/Activities/newactivities2.jpg";
import newactivities3 from "../../assets/images/LavishaWay/Activities/newactivities3.jpg";
import newactivities4 from "../../assets/images/LavishaWay/Activities/newactivities4.jpg";
import newactivities5 from "../../assets/images/LavishaWay/Activities/newactivities5.jpg";

import newactivities7 from "../../assets/images/LavishaWay/Activities/newactivities7.jpg";
// import hl1 from "../../assets/images/LavishaWay/Highlight/hl-1.jpg";
// import hl2 from "../../assets/images/LavishaWay/Highlight/hl-2.jpg";
// import hl3 from "../../assets/images/LavishaWay/Highlight/hl-3.jpg";

import sc1 from "../../assets/images/LavishaWay/SkinCare/sc-1.jpg";
import sc2 from "../../assets/images/LavishaWay/SkinCare/sc-2.jpg";
import sc3 from "../../assets/images/LavishaWay/SkinCare/sc-3.jpg";
import sc4 from "../../assets/images/LavishaWay/SkinCare/sc-4.jpg";

import sc6 from "../../assets/images/LavishaWay/SkinCare/sc-6.jpg";
import sc7 from "../../assets/images/LavishaWay/SkinCare/sc-7.jpg";

const all = [
  {
    id: 1,
    src: event1,
  },
  {
    id: 2,
    src: event2,
  },
  {
    id: 3,
    src: event3,
  },
  {
    id: 4,
    src: event4,
  },
  {
    id: 5,
    src: event5,
  },
  {
    id: 6,
    src: event6,
  },
  {
    id: 1,
    src: newactivities1,
  },
  {
    id: 2,
    src: newactivities2,
  },
  {
    id: 3,
    src: newactivities3,
  },

  {
    id: 4,

    src: newactivities4,
  },
  {
    id: 5,
    src: newactivities5,
  },
  {
    id: 7,
    src: newactivities7,
  },
  // {
  //   id: 1,
  //   src: hl1,
  // },
  // {
  //   id: 2,
  //   src: hl2,
  // },
  // {
  //   id: 3,

  //   src: hl3,
  // },
  {
    id: 1,
    src: sc1,
  },
  {
    id: 2,
    src: sc2,
  },
  {
    id: 3,
    src: sc3,
  },
  {
    id: 4,
    src: sc4,
  },

  {
    id: 6,
    src: sc6,
  },
  {
    id: 7,
    src: sc7,
  },
];

export default all;
