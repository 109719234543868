import React, { useState, useRef } from "react";
import { BsArrowRightCircleFill } from "react-icons/bs";
import { TfiArrowLeft, TfiArrowRight } from "react-icons/tfi";
import about2 from "../assets/images/academy2.png";
import about1 from "../assets/images/academy1.jpg";
import reapeImg from "../assets/images/reaping-success.jpg";
import reapeAmbassador from "../assets/images/reapeAmbassador.jpg";
import empowerImg from "../assets/images/BATrainingPillars.webp";
import AccordionItem from "../components/accordionComponent/accordionItem";
import AccordionWrapper from "../components/accordionComponent/accordion";
import Modal from "../components/modal";
import ModalContent from "../components/modalcontent";
import faq from "../assets/data/faq";
import baNews from "../assets/data/baNews";
import businessAssociate from "../assets/data/businessAssociate";


const Academy = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  const [currentIndex, setCurrentIndex] = React.useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % baNews.length);
  };

  const handlePrev = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + baNews.length) % baNews.length
    );
  };

  return (
    <div className="bg-primary">
      <div>
        <div className="academy-wrapper bg-primary">
          <div className="bg-image-academy">
            <div className="academy-hero-col">
              <div className="academy-hero-bg-col-1">
                <div className="header-title-left">
                  EMPOWERING
                  <div> YOU TO</div>
                </div>
              </div>

              <div className="academy-hero-bg-col-2">
                <div className="header-title-right">
                  EMPOWER
                  <div>OTHERS</div>
                </div>
              </div>
            </div>

            <div className="academy-btn-hero-group">
              <button
                className="btn btn-hero"
                onClick={() => setModalOpen(true)}
              >
                <div className="btn-text"> Become a BA Today</div>
                <div className="btn-icon">
                  <BsArrowRightCircleFill size={20} />
                </div>
              </button>
            </div>
          </div>
        </div>

        <div className="section-vw100 bg-white">
          <div className=" section-wwr">
            <div className="section section-wwr-header">
              <div className="wwr-header-text">
                <div className="section-item-1">
                  <div className="section-tag">
                    <div className="dot bg-active"></div>Who We Are
                  </div>
                  <div className="section-title">
                    Growth for All, a Flourishing Future
                  </div>
                </div>

                <div className="section-item-2">
                  <div className="section-detail color-text-dark">
                    At Lavisha Malaysia, we focus on equipping individuals to
                    become experts in identifying and capitalizing business
                    opportunities with precision and commitment. With a keen
                    ability to identify and leverage resources others may
                    overlook, our BAs are not just professionals but are
                    advocates of innovation and excellence.
                  </div>
                </div>
              </div>
            </div>

            <div className="section-vw100">
              <div className="wwr-header-image-group">
                <div className=" wwr-img">
                  <div className="wwr-img-group">
                    <img src={about1}></img>
                    <img src={about2}></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section-vw100 bg-white">
          <div className="section section-empower ">
            <div className="section-empower-group empower-bg-image">
              <div className="section-empower-1">
                <div className="section-item-1">
                  <div className="section-tag">
                    <div className="dot bg-active"></div>What is a BA?
                  </div>
                  <div className="section-title">
                    Empowering you to empower others
                  </div>

                  <div className="section-detail color-text-grey">
                    Business Associates are individuals that prioritize holistic
                    well-being, achieving physical health, financial abundance,
                    and personal fulfillment.
                  </div>
                </div>
              </div>

              <div className="empower-img-group-mobile">
                <img src={empowerImg} className="empower-img"></img>
              </div>

              <div className="section-empower-2">
                <div className="empower-box">
                  <div className="section-detail-large">Look</div>

                  <div className="section-detail-small color-text-grey">
                    BAs become living embodiments of the Lavisha philosophy
                    through confidence and authenticity.
                  </div>
                </div>
                <div className="empower-box">
                  <div className="section-detail-large">Purpose</div>

                  <div className="section-detail-small color-text-grey">
                    BAs are empowered to change lives by guiding individuals on
                    their glow-up journeys.
                  </div>
                </div>
                <div className="empower-box">
                  <div className="section-detail-large">Legacy</div>

                  <div className="section-detail-small color-text-grey">
                    By being a part of Lavisha, BAs create a legacy that extends
                    beyond their time as representatives, leaving a mark on the
                    world.
                  </div>
                </div>
                <div className="empower-box">
                  <div className="section-detail-large">Wealth</div>

                  <div className="section-detail-small color-text-grey">
                    Lavisha’s commitment to providing high-quality products and
                    services opens doors for BAs to create wealth while
                    promoting wellness.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section-vw100 bg-white">
          <div className="section-reape-success">
            <div className="reape-grid">
              <div className="reape-success-header-text">
                <div className="section-item-1">
                  <div className="section-title">
                    REAPING
                    <div>SUCCESS</div>
                  </div>
                </div>
              </div>
              <div className="reape-header-image">
                <div className="reape-header-image-group">
                  <img src={reapeImg} className="reape-image"></img>
                </div>
              </div>
              <div className="reape-ba-detail-mobile">
                <div className="section-detail color-text-dark">
                  Lavisha’s philosophy is convergence. Convergence involves
                  gradual transformation of two different individuals into
                  something more similar, fostering collaboration over time,
                  unlocking the potential of each individual through co-creation
                  to achieve greater accomplishments.
                </div>
              </div>

              <div className="reape-ba-image-group">
                <img src={reapeAmbassador} className="reape-ba-image"></img>
              </div>
              <div className="reape-ba-detail-web">
                <div className="detail-web">
                  <div className="section-detail color-text-dark">
                    Lavisha’s philosophy is convergence. Convergence involves
                    gradual transformation of two different individuals into
                    something more similar, fostering collaboration over time,
                    unlocking the potential of each individual through
                    co-creation to achieve greater accomplishments.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section section-business-associate">

        <div className="business-associate-wrapper">
        <div className="business-associate-header">
          <div className="section-title">Business Associates Resources</div>
        </div>
        <div className="business-associate-content">
          <div className="business-associate-content-inner">
            {businessAssociate.map((businessA, index) => (
              <div className="business-associate-card" key={index}>
                <div className="business-associate-img-box">
                  <img className="business-associate-img" src={businessA.src} />
                </div>

                <div className="business-associate-title-group">
                  <div className="business-associate-title section-detail-large">
                    {businessA.title}
                  </div>

                
                </div>
              </div>
            ))}
          </div>
        </div>
        </div>
   
      </div>

      <div className="section section-vw100 ">
        <div className="section-ba-news">
          <div className="ba-news-header">
            <div className="section-item-1">
              <div className="section-tag">
                <div className="dot bg-active"></div>Our Business Associates
              </div>
              <div className="section-title">Stay in the Know</div>
            </div>

            <div className="section-item-2">
              <button className="left-arrow" onClick={handlePrev}>
                <TfiArrowLeft></TfiArrowLeft>
              </button>
              <button className="right-arrow" onClick={handleNext}>
                <TfiArrowRight></TfiArrowRight>
              </button>
            </div>
          </div>

          <div className="content-slider">
            <div className="ba-news-content">
              <div className="ba-news-card ">
                {baNews
                  .slice(currentIndex, currentIndex + 2)
                  .map((card, index) => (
                    <div className="ba-news-detail-group" key={card.id}>
                      <div className="ba-news-inner">
                        <div className="ba-news-inner-detail">
                          <div className="detail-flex">
                            <div className="section-detail-small color-text-dark">
                              {card.date}
                            </div>

                            <div className="section-detail-small color-text-grey ">
                              {card.type}
                            </div>
                          </div>

                          <div className="section-detail color-text-dark">
                            {card.detail}
                          </div>
                        </div>

                        <div className="ba-news-image-group">
                          <img className="ba-news-image" src={card.src} />
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-vw100 bg-white">
        <div className="section-faq">
          <div className="section-faq-container">
            <div className="faq-header">
              <div className="section-item-1">
                <div className="section-tag">
                  <div className="dot bg-active"></div>FAQ
                </div>
                <div className="section-title">Frequently Asked Questions</div>
              </div>
            </div>
            <div className="faq-content">
              <div className="faq-accordion">
                <div className="accordion">
                  <AccordionWrapper>
                    {faq.map((item, index) => (
                      <AccordionItem
                        key={index}
                        index={index}
                        title={item.title}
                        subtitle={item.subtitle}
                        description={item.description}
                      />
                    ))}
                  </AccordionWrapper>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <Modal onClose={() => setModalOpen(false)}>
          <ModalContent></ModalContent>
        </Modal>
      )}
    </div>
  );
};

const Box = ({ v }) => <div className="box">{v}</div>;

export default Academy;
