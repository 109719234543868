//team
import team1 from "../../assets/images/about_team/ellycia-crop.jpg";
import team2 from "../../assets/images/about_team/felicia-crop.jpg";
import team3 from "../../assets/images/about_team/jessie-crop.jpg";
import team4 from "../../assets/images/about_team/jovy-crop.jpg";
import team5 from "../../assets/images/about_team/calvern-crop.jpg";
import team6 from "../../assets/images/about_team/lisun-crop.jpg";
import team7 from "../../assets/images/about_team/rachel-crop.jpg";
const team = [
 {
      id: 1,
      src: team6,
      team: "LiSun Liong",
      position: "CEO",
    },
    {
      id: 2,
      src: team7,
      team: "Rachel Lee",
      position: "CSO",
    },
    {
      id: 3,
      src: team5,
      team: "Calvern Yuen",
      position: "Digital Marketing",
    },
    {
      id: 4,
      src: team3,
      team: "Jessie Tai",
      position: "Finance",
    },
    {
      id: 5,
      src: team2,
      team: "Felicia Tai",
      position: "Customer Service",
    },
    {
      id: 6,
      src: team1,
      team: "Ellycia Fung",
      position: "Product Development",
    },
    {
      id: 7,
      src: team4,
      team: "Jovy Ng",
      position: "Admin",
    },
];

export default team;
