import React from "react";

const Tabs = ({ active, onChange, children }) => {
  return (
    <>
      <div className="tab-title">
        <div className="tab-title-group">
          {children.map((c, index) => (
            <button
              onClick={() => onChange(index)}
              className={
                active === index ? "button-tab activeTab" : "button-tab"
              }
              key={index}
            >
              {c.props.title}
            </button>
          ))}
        </div>
      </div>
      <div className="tab-content">{children[active]}</div>
    </>
  );
};

export default Tabs;
