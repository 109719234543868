import React, { useState } from "react";
import { BsArrowRightCircleFill, BsFacebook } from "react-icons/bs";
import footerLogo from "../../assets/icons/Lavisha_White.svg";
import { BsTiktok } from "react-icons/bs";
import { BsInstagram } from "react-icons/bs";
import { NavLink } from "react-router-dom";
import Modal from "../../components/modal";
import ModalContent from "../../components/modalcontent";
import { Link } from "react-router-dom";

const SocialIcon = ({ icon: Icon }) => (
  <Icon className="social-icon" size={30} />
);
// Footer component
const Footer = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  const items = [
    // Social media icons
    {
      type: "lavishaMalaysia",
      link: "https://www.facebook.com/lavishamalaysia/",
      icon: BsFacebook,
    },
    {
      type: "lavishaMalaysia",
      link: "https://www.instagram.com/lavisha.malaysia/?hl=en",
      icon: BsInstagram,
    },
    {
      type: "lavishaMalaysia",
      link: "https://www.tiktok.com/@lavishamalaysia",
      icon: BsTiktok,
    },
    {
      type: "lavishaPrestige",
      link: "https://www.facebook.com/lavishaprestige",
      icon: BsFacebook,
    },

    {
      type: "lavishaPrestige",
      link: "https://www.instagram.com/lavishaprestige/?hl=en",
      icon: BsInstagram,
    },
    {
      type: "lavishaPrestige",
      link: "https://www.tiktok.com/@lavishaprestige",
      icon: BsTiktok,
    },

    // Footer sections
    {
      type: "section",
      title: "Navigation",
      items: [
        {
          content: "Home",
          href: "/",
        },
        {
          content: "About Us",
          href: "/about-us",
        },
        {
          content: "Lavisha Way",
          href: "/lavisha-way",
        },
        {
          content: "BA Academy",
          href: "/ba-academy",
        },
      ],
    },
    {
      type: "section",
      title: "Our Brands",

      items: [
        {
          content: "LBB",
          href: "https://lbb.lavishamsia.com/",
        },
        {
          content: "Le Miele",
          href: "http://lemiele.lavishamsia.com/",
        },
        {
          content: "L’Arc",
          href: "http://larc.lavishamsia.com/",
        },
      ],

      extras: ["More Coming Soon"],
    },
    {
      type: "section",
      title: "Company",
      items: [
        {
          content: "Terms of Use",
          href: "/",
        },
        {
          content: "Privacy Policy",
          href: "/",
        },
      ],
    },
    // { type: "section", title: "Legal", items: ["Claim", "Policy", "Terms"] },
  ];
  return (
    <>
      <div className="footer">
        <div className="footer-container">
          <div className="footer-row">
            <div className="footer-title-section">
              <div className="footer-title-group">
                <Link to="/">
                  <img className="footer-logo" src={footerLogo}></img>
                </Link>

                <div className="footer-title-text section-detail">
                  A collective of passionate beauty and wellness entrepreneurs,
                  championing the idea of holistic well-being and empowering
                  your glow from inside out.
                </div>

                <button
                  className="btn btn-hero"
                  onClick={() => setModalOpen(true)}
                >
                  <div className="btn-text">Become a BA Today</div>
                  <div className="btn-icon">
                    <BsArrowRightCircleFill size={20} />
                  </div>
                </button>
              </div>
            </div>

            {items.map((item, index) =>
              item.type === "section" ? (
                <div className={`footer-col ${item.title}`} key={index}>
                  <div className="footer-col-title section-detail-large ">
                    {item.title}
                  </div>
                  <ul className="footer-col-item section-detail-small ">
                    {/* Mapping over items in each section */}
                    {item.items.map((subItem, subIndex) => (
                      <li key={subIndex} className={items.title}>
                        <Link to={subItem.href}>{subItem.content}</Link>
                      </li>
                    ))}

                    <li className="extra"> {item.extras}</li>
                  </ul>
                </div>
              ) : null
            )}

            <div className="footer-company-info ">
              <div className="footer-company-info-group address">
                <div className="footer-col-title section-detail-large ">
                  Address
                </div>
                <ul className="footer-col-item section-detail-small ">
                  <li className="items ">
                    08-10 to 08-12 Millerz Square, No 357, Jalan Old Klang Road,
                    58000 Kuala Lumpur, Malaysia.
                  </li>
                </ul>
              </div>

              <div className=" footer-company-info-group telephone">
                <div className="footer-col-title section-detail-large ">
                  Telephone
                </div>
                <ul className="footer-col-item section-detail-small ">
                  <li className="items">+603-8684 2416</li>

                  <li className="items">+6012-512 4162</li>
                </ul>
              </div>
            </div>

            <div className="social-icon-group">
              <div className="icon-group">
                <div className="footer-social-text  section-detail-large ">Lavisha Malaysia</div>
                {/* Mapping over social icons and rendering the SocialIcon component */}
                <div className="footer-social-icon">
                  {items.map((item, index) =>
                    item.type === "lavishaMalaysia" ? (
                      <Link key={index} to={item.link}>
                        {" "}
                        <SocialIcon icon={item.icon} />
                      </Link>
                    ) : null
                  )}
                </div>
              </div>
              <div className="icon-group prestige">
                <div className="footer-social-text section-detail-large ">Lavisha Prestige</div>
                {/* Mapping over social icons and rendering the SocialIcon component */}

                <div className="footer-social-icon">
                  {items.map((item, index) =>
                    item.type === "lavishaPrestige" ? (
                      <Link key={index} to={item.link}>
                        {" "}
                        <SocialIcon icon={item.icon} />
                      </Link>
                    ) : null
                  )}
                </div>
              </div>
            </div>

            <div className="copyright footer-col-item color-primary">
              <div className="section-detail-small ">
                © 2024 LBB Malaysia Sdn. Bhd. (1509153U)
              </div>
              <div className="footer-tnc ">
                <li className="section-detail-small">
                  <NavLink> Term of Use</NavLink>
                </li>

                <li className="section-detail-small">
                  <NavLink>Privacy Policy</NavLink>
                </li>
              </div>
              <div className="right-reserved section-detail-small ">
                All Rights Reserved
              </div>
            </div>
          </div>
        </div>
      </div>

      {isModalOpen && (
        <Modal onClose={() => setModalOpen(false)}>
          <ModalContent></ModalContent>
        </Modal>
      )}
    </>
  );
};
export default Footer;
