import React, { useState } from "react";
import photos from "../assets/data/imgGallery";
import { ColumnsPhotoAlbum } from "react-photo-album";
import "react-photo-album/columns.css";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";

const Gallery = () => {
  const [index, setIndex] = useState(-1);
  return (
    <>
      <div className="gallery-web">
        <ColumnsPhotoAlbum
          photos={photos}
          columns={(containerWidth) => {
            if (containerWidth < 768) return 2;
            if (containerWidth < 1366) return 5;
            return 5;
          }}
          onClick={({ index: current }) => setIndex(current)}
          spacing={(containerWidth) => {
            if (containerWidth < 768) return 12;
            if (containerWidth < 1024) return 16;
            if (containerWidth < 1366) return 24;
            return 24;
          }}
          defaultContainerWidth="100%"
          render={{
            wrapper: ({ style, ...rest }) => (
              <div
                style={{
                  ...style,

                  transition:
                    "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                }}
                {...rest}
              />
            ),
          }}
        />
      </div>

      <Lightbox
        slides={photos}
        open={index >= 0}
        index={index}
        close={() => setIndex(-1)}
        plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
      />
    </>
  );
};

export default Gallery;
