import sc1 from "../../assets/images/LavishaWay/SkinCare/sc-1.jpg";
import sc2 from "../../assets/images/LavishaWay/SkinCare/sc-2.jpg";
import sc3 from "../../assets/images/LavishaWay/SkinCare/sc-3.jpg";
import sc4 from "../../assets/images/LavishaWay/SkinCare/sc-4.jpg";
import sc6 from "../../assets/images/LavishaWay/SkinCare/sc-6.jpg";
import sc7 from "../../assets/images/LavishaWay/SkinCare/sc-7.jpg";


const workshops = [
  {
    id: 1,
    src: sc1,
  },
  {
    id: 2,
    src: sc2,
  },
  {
    id: 3,
    src: sc3,
  },
  {
    id: 4,
    src: sc4,
  },

  {
    id: 6,
    src: sc6,
  },
  {
    id: 7,
    src: sc7,
  },


];

export default workshops;
