import React, { useContext } from "react";
import PropTypes from "prop-types";
import { AccordionContext } from "./accordion";
import { TfiMinus, TfiPlus } from "react-icons/tfi";

const AccordionItem = (props) => {
  let indexPlus;

  const indexCount = (index) => {
    indexPlus = index + 1;
    return indexPlus;
  };

  const { active, setActive } = useContext(AccordionContext);

  const eventHandler = (e, index) => {
    e.preventDefault();
    setActive(index);
  };

  return (
    <div className="accordion-item">
      <h3 className="accordion-title">
        <button
          onClick={(e) => eventHandler(e, props.index)}
          className={active === props.index ? " active" : "inactive"}
          aria-expanded={active === props.index ? "true" : "false"}
          aria-controls={"sect-" + indexCount(props.index)}
          aria-disabled={active === props.index ? "true" : "false"}
        >
          <div>
            <span className="title-wrapper">{props.title}</span>
          </div>

          <span className="icon-wrapper">
            {active === props.index ? (
              <TfiPlus className="plus"></TfiPlus>
            ) : (
              <TfiMinus className="minus"></TfiMinus>
            )}
          </span>
        </button>
      </h3>
      <div className="accordion-panel">
        <div
          id={"sect-" + indexCount(props.index)}
          className={
            active === props.index
              ? "section-detail panel-open"
              : "section-detail panel-close"
          }
        >
          <div className="section-detail-small">{props.subtitle}</div>
          {props.description}
        </div>
      </div>
    </div>
  );
};

AccordionItem.propTypes = {
  index: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default AccordionItem;
