import hl1 from "../../assets/images/LavishaWay/Highlight/hl-1.jpg";
import hl2 from "../../assets/images/LavishaWay/Highlight/hl-2.jpg";
import hl3 from "../../assets/images/LavishaWay/Highlight/hl-3.jpg";

const highlight = [
  {
    id: 1,
    src: hl1,
    type: "Highlight, Apparel",
    title:
      "Elevate your look and wellness with Le Miele! Our versatile pants blend style and smart tech for the ultimate in fashion and function. ",
  },
  {
    id: 2,
    src: hl2,
    type: "Highlight, Functional Food",
    title:
      "Transform your wellness with our new Functional Food! Blending ancient wisdom and modern science for a holistic boost.",
  },
  {
    id: 3,
    src: hl3,
    type: "Highlight, Device",
    title:
      "Discover holistic wellness like never before with advanced tech and AI customized for your unique needs. Stay tuned for your ultimate wellness upgrade!",
  },
];

export default highlight;
