import React, { useState } from "react";
import { useEffect } from "react";
import ReactPlayer from "react-player";
import lavishaVideo from "../assets/images/lavisha.mp4"

const VideoPlayer = () => {
    
    const [playing, setPlaying] = useState(false);
    const [controls, setControls] = useState(false);
  
    const onPlayMedia = () => {
      setPlaying(true);
    };
  
    const onEndMedia = () => {
     
      setControls(false);
    };
  
    useEffect(() => {
      setTimeout(() => {
        setPlaying(true);
      }, 3000);
    }, []);
  
    useEffect(() => {
      setTimeout(() => {

        setControls(true);
      }, 5000);
    }, []);
  

  
    return (
      <div className="video-player">
        <div className="player-wrapper">
          <ReactPlayer
            className="react-player"
            url={lavishaVideo}
            controls={controls}
            pip={true}
            playing={playing}
            onPlay={onPlayMedia}
            onEnded={onEndMedia}
           loop
           autoplay
          />
  
         
        </div>
      </div>
    );
  };
  
  export default VideoPlayer;