// src/App.js
import { Routes, Route } from "react-router-dom";
import React from "react";
import "./assets/style/header.css";
import "./assets/style/footer.css";
import "./assets/style/home_web.css";
import "./assets/style/home_mobile.css";
import "./assets/style/about_web.css";
import "./assets/style/about_mobile.css";
import "./assets/style/news_web.css";
import "./assets/style/news_mobile.css";
import "./assets/style/academy_web.css";
import "./assets/style/academy_mobile.css";
import "./App.css";
import Header from "./layout/sidebar/Header";
import Home from "./pages/Home";
import About from "./pages/About";
import News from "./pages/News";
import Academy from "./pages/Academy";
import Footer from "./layout/footer/footer";
import ScrollToTop from "./components/scrollToTop";
const App = () => {
  return (
    <div>
      <ScrollToTop></ScrollToTop>
      <Header></Header>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/lavisha-way" element={<News />} />
        <Route path="/ba-academy" element={<Academy />} />
      </Routes>
      <Footer></Footer>
    </div>
  );
};

export default App;
