import React from "react";

function Modal({ children, onClose }) {
  return (
    <div className="overlay">
      <div className="modal">
        {children}
        <button className="btn-close" onClick={onClose}>
          <div className="close-button">
            <div className="in">
              <div className="close-button-block"></div>
              <div className="close-button-block"></div>
            </div>
            <div className="out">
              <div className="close-button-block"></div>
              <div className="close-button-block"></div>
            </div>
          </div>
        </button>
      </div>
    </div>
  );
}

export default Modal;
