import React, { useState } from "react";
import about2 from "../assets/images/about_us_2.jpg";
import about1 from "../assets/images/about_us_1.jpg";
import Modal from "../components/modal";
import ModalContent from "../components/modalcontent";
import team from "../assets/data/team";
const About = () => {

  const [isModalOpen, setModalOpen] = useState(false);
  return (
    <div>
      <div className="bg-primary">
        <div className="section-about bg-light-grey ">
          <div className="section section-about-group">
            <div className="about-text">
              <div className="section-about-1">
                <div className="section-tag">
                  <div className="dot bg-active"></div>Who We Are
                </div>
                <div className="section-title">
                  A collective of passionate beauty and wellness entrepreneurs
                </div>
              </div>

              <div className="section-about-2">
                <div className="section-detail color-text-dark">
                  We are on a mission to transform lives. We believe in the
                  power of holistic well-being and are committed to providing
                  individuals with the tools and resources they need to thrive.
                  Our dedicated network of Business Associates (BAs) lies at the
                  heart of the Lavisha ecosystem, empowering others on their
                  wellness journeys.
                </div>
              </div>
            </div>
            <div className=" about-img">
              <div className="about-img-group">
                <img src={about1}></img>
                <img src={about2}></img>
              </div>
            </div>
          </div>
        </div>
        <div className="section section-vision ">
          <div className="section-vision-group vision-bg-image">
            <div className="section-vision-1">
              <div className="section-title">
                Our
                <div>Vision</div>
              </div>
            </div>

            <div className="section-vision-2">
              <div className="section-detail">
                We envision a world where individuals prioritize holistic
                well-being, achieving physical health, financial abundance, and
                personal fulfillment.
              </div>
          
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white">
        <div className="section section-team">
          <div className="section-team-group">
            <div className="section-team-title-group">
              <div className="section-team-title">
                <div className="section-tag">
                  <div className="dot bg-active"></div>The Team
                </div>
                <div className="section-title">Meet the Team</div>
              </div>
              <div className="section-team-desc">
                <div className="section-detail color-text-dark">
                  Meet the passionate Lavisha founders and inspiring Management team who are shaping the future of wellness.
                </div>
              </div>
            </div>
          </div>

          <div className="section-team-image">
            <div className="team-image-group">
              {team.map((teams, index) => (
                <div className="card" key={teams.id}>
                  <div className="member-img-box">
                    <img className="member-img" src={teams.src} />
                  </div>

                  <div className="team-member-info">
                    <div className="team-member-name section-detail-large">
                      {teams.team}
                    </div>
                    <div className="member-role section-detail-small color-text-grey">
                      {teams.position}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <Modal onClose={() => setModalOpen(false)}>
          <ModalContent></ModalContent>
        </Modal>
      )}
    </div>
  );
};

export default About;
