import React from "react";
import { SlPhone, SlEnvolope } from "react-icons/sl";

function ModalContent({ children, onClose }) {
  return (
    <div>
      <div className="modal-title">
        <div className="text-center section-title-modal">
          Let's get in touch
        </div>
      </div>
      <div className="modal-grid">
        <div className="modal-grid-col section-detail ">
          <div className="contact-icon">
            <SlPhone size={30}></SlPhone>
          </div>
          012 512 4162
        </div>

        <div className="modal-grid-col section-detail ">
          <div className="contact-icon">
            <SlEnvolope size={30}></SlEnvolope>
          </div>
          info@lbbmalaysia.com
        </div>
      </div>
    </div>
  );
}

export default ModalContent;
