import event1 from "../../assets/images/LavishaWay/Events/event-1.jpg";
import event2 from "../../assets/images/LavishaWay/Events/event-2.jpg";
import event3 from "../../assets/images/LavishaWay/Events/event-3.jpg";
import event4 from "../../assets/images/LavishaWay/Events/event-4.jpg";
import event5 from "../../assets/images/LavishaWay/Events/event-5.jpg";
import event6 from "../../assets/images/LavishaWay/Events/event-6.jpg";
const events = [
  {
    id: 1,
    src: event1,
  },
  {
    id: 2,
    src: event2,
  },
  {
    id: 3,
    src: event3,
  },
  {
    id: 4,
    src: event4,
  },
  {
    id: 5,
    src: event5,
  },
  {
    id: 6,
    src: event6,
  },
];

export default events;
