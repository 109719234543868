import newactivities1 from "../../assets/images/LavishaWay/Activities/newactivities1.jpg";
import newactivities2 from "../../assets/images/LavishaWay/Activities/newactivities2.jpg";
import newactivities3 from "../../assets/images/LavishaWay/Activities/newactivities3.jpg";
import newactivities4 from "../../assets/images/LavishaWay/Activities/newactivities4.jpg";
import newactivities5 from "../../assets/images/LavishaWay/Activities/newactivities5.jpg";

import newactivities7 from "../../assets/images/LavishaWay/Activities/newactivities7.jpg";
const activity = [
  {
    id: 1,
    src: newactivities1,
    type: "Highlight, Apparel",
    title:
      "Elevate your look and wellness with Le Miele! Our versatile pants blend style and smart tech for the ultimate in fashion and function. ",
  },
  {
    id: 2,
    src: newactivities2,
    type: "Highlight, Functional Food",
    title:
      "Transform your wellness with our new Functional Food! Blending ancient wisdom and modern science for a holistic boost.",
  },
  {
    id: 3,

    src: newactivities3,
    type: "Highlight, Device",
    title:
      "Discover holistic wellness like never before with advanced tech and AI customized for your unique needs. Stay tuned for your ultimate wellness upgrade!",
  },

  {
    id: 4,

    src: newactivities4,
    type: "Highlight, Device",
    title:
      "Discover holistic wellness like never before with advanced tech and AI customized for your unique needs. Stay tuned for your ultimate wellness upgrade!",
  },
  {
    id: 5,

    src: newactivities5,
    type: "Highlight, Device",
    title:
      "Discover holistic wellness like never before with advanced tech and AI customized for your unique needs. Stay tuned for your ultimate wellness upgrade!",
  },

  {
    id: 7,

    src: newactivities7,
    type: "Highlight, Device",
    title:
      "Discover holistic wellness like never before with advanced tech and AI customized for your unique needs. Stay tuned for your ultimate wellness upgrade!",
  },
];

export default activity;
