import Advancement from "../../assets/images/business_associates/Advancement.png";
import Express from "../../assets/images/business_associates/Express.png";
import JumpStart from "../../assets/images/business_associates/JumpStart.png";
import Personal from "../../assets/images/business_associates/Personal.png";
import Reinventing from "../../assets/images/business_associates/Reinventing.png";

import Strenthening from "../../assets/images/business_associates/Strenthening.png";

const businessAssociate = [
  {
    id: 1,
    src: JumpStart,
    title: "Jump Start Your Glow ",
  },
  {
    id: 2,
    src: Reinventing,
    title: "Reinventing Your Glow",
  },
  {
    id: 3,
    src: Personal,
    title: "Personal Growth",
  },
  {
    id: 4,
    src: Express,
    title: "Lavisha Express ",
  },
  {
    id: 5,
    src: Strenthening,
    title: "Strengthening Sales",
  },
  {
    id: 6,
    src: Advancement,
    title: "Lavisha Journey to Advancement",
  },
];

export default businessAssociate;
